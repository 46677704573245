/* eslint-disable */
import { createRouter, createWebHistory } from "vue-router";
import Layout from "@/components/Layout/index.vue";
import TopNavgation from "@/components/TopNavgation/index.vue";
import store from "@/store";
import { globalNavs } from "@/navConfig";

const constantRouter = [
  {
    path: "/livePlay/:id/:detailId",
    name: "课程直播",
    component: () => import(/* webpackChunkName: "livePlay" */ "@/views/livePlay/index")
  },
  {
    path: "/replayLive",
    name: "课程录播",
    component: () => import(/* webpackChunkName: "replayLive" */ "@/views/replayLive/index")
  },
  {
    path: "/videoReplay",
    name: "课程播放",
    component: () => import(/* webpackChunkName: "videoReplay" */ "@/views/userCourses/videoReplay")
  }
];

const innerMenu = [
  {
    path: "/home",
    name: "home",
    meta: globalNavs.find(item => item.key === "home"),
    component: () => import(/* webpackChunkName: "home" */ "@/views/home")
  },
  {
    path: "/submitArticle",
    name: "提交作文",
    component: () => import(/* webpackChunkName: "submitArticle" */ "@/views/writing/submitArticle")
  },
  {
    path: "/articleInfo/:id",
    name: "作文详情",
    component: () => import(/* webpackChunkName: "articleInfo" */ "@/views/writing/articleInfo")
  },
  {
    path: "/writingReport/:id",
    name: "写作报告",
    component: () => import(/* webpackChunkName: "writingReport" */ "@/views/writing/writingReport")
  },
  {
    path: "/scanTopay",
    name: "扫码支付",
    component: () => import(/* webpackChunkName: "scanTopay" */ "@/views/scanTopay")
  },
  {
    path: "/result",
    name: "支付结果",
    component: () => import(/* webpackChunkName: "result" */ "@/views/result")
  },
  {
    path: "/goodsInfo",
    name: "商品详情",
    component: () => import(/* webpackChunkName: "goodsInfo" */ "@/views/goodsInfo")
  },
  {
    path: "/submitOrder",
    name: "提交订单",
    component: () => import(/* webpackChunkName: "submitOrder" */ "@/views/submitOrder")
  },
  {
    path: "/agreement",
    name: "服务协议",
    component: () => import(/* webpackChunkName: "agreement" */ "@/views/agreement")
  }
];

export const menuList = [
  {
    path: "/userCourses",
    name: "我的课程",
    meta: { key: "userCourses", title: "我的课程" },
    component: () => import(/* webpackChunkName: "userCourses" */ "@/views/userCourses/index")
  },
  {
    path: "/courseDetail/:id",
    name: "课程详情",
    meta: { key: "userCourses", title: "课程详情" },
    isSubMenu: true,
    component: () =>
      import(/* webpackChunkName: "courseDetail" */ "@/views/userCourses/courseDetail")
  },
  {
    path: "/checkList",
    name: "选课列表",
    meta: { key: "userCourses", title: "选课列表" },
    isSubMenu: true,
    component: () => import(/* webpackChunkName: "checkList" */ "@/views/userCourses/checkList")
  },
  {
    path: "/writing",
    name: "写作批改",
    meta: { key: "writing", title: "写作批改" },
    component: () => import(/* webpackChunkName: "writing" */ "@/views/writing/index")
  },
  {
    path: "/oral",
    name: "口语陪练",
    meta: { key: "oral", title: "口语陪练" },
    component: () => import(/* webpackChunkName: "oral" */ "@/views/oral")
  },
  {
    path: "/bookCourse",
    name: "预约课程",
    isSubMenu: true,
    meta: { key: "oral", title: "预约课程" },
    component: () => import(/* webpackChunkName: "bookCourse" */ "@/views/oral/bookCourse")
  },
  {
    path: "/userOrders",
    name: "我的订单",
    meta: { key: "userOrders", title: "我的订单" },
    component: () => import(/* webpackChunkName: "userOrders" */ "@/views/userOrders/index")
  },
  {
    path: "/orderDetail/:code",
    name: "订单详情",
    isSubMenu: true,
    meta: { key: "userOrders", title: "订单详情" },
    component: () => import(/* webpackChunkName: "orderDetail" */ "@/views/userOrders/orderDetail")
  },
  {
    path: "/addComment/:code",
    name: "发表评价",
    isSubMenu: true,
    meta: { key: "addComment", title: "发表评价" },
    component: () => import(/* webpackChunkName: "orderDetail" */ "@/views/userOrders/addComment")
  },
  {
    path: "/userInvoices",
    name: "我的发票",
    meta: { key: "userInvoices", title: "我的发票" },
    component: () => import(/* webpackChunkName: "userInvoices" */ "@/views/userInvoices/index")
  },
  {
    path: "/viewInvoice",
    name: "查看发票",
    meta: { key: "userInvoices", title: "查看发票" },
    isSubMenu: true,
    component: () =>
      import(
        /* webpackChunkName: "viewInvoiceOrAgreement" */ "@/views/userInvoices/viewInvoiceOrAgreement"
      )
  },
  {
    path: "/viewAgreement",
    name: "查看协议",
    meta: { key: "userOrders", title: "查看协议" },
    isSubMenu: true,
    component: () =>
      import(
        /* webpackChunkName: "viewInvoiceOrAgreement" */ "@/views/userInvoices/viewInvoiceOrAgreement"
      )
  },
  {
    path: "/userCoupons",
    name: "优惠券",
    meta: { key: "userCoupons", title: "优惠券" },
    component: () => import(/* webpackChunkName: "userCoupons" */ "@/views/userCoupons/index")
  },
  {
    path: "/userInfo",
    name: "个人信息",
    meta: { key: "userInfo", title: "个人信息" },
    component: () => import(/* webpackChunkName: "userInfo" */ "@/views/userInfo/index")
  }
];

const dynamicRouter = [
  {
    component: Layout,
    children: [...menuList]
  }
];

const innerRouter = [
  {
    component: TopNavgation,
    children: [...innerMenu]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [...constantRouter, ...innerRouter, ...dynamicRouter]
});

router.beforeEach((to, from, next) => {
  const curDeviceIsH5 = window.navigator.userAgent.indexOf("Mobile") >= 0;
  if (curDeviceIsH5) {
    let reg = /^\/goodsInfo(.*)/g;
    let str = to.fullPath;
    if (reg.test(str)) {
      let s = str.replace(reg, ($0, $1, $2, $3) => {
        return "/h5/pages/goodsInfo/index" + $1;
      });
      window.location.href = window.location.origin + s;
    } else {
      window.location.href = window.location.origin + "/h5/";
    }
  } else {
    if (to.path === "/") {
      next("/home");
      return false;
    }
    next();
  }
});

router.afterEach((to, from, next) => {
  document.body.scrollTop = 0;
  store.commit("global/UPDATE_STATE_VALUE", { key: "currentNavKey", data: (to.meta || {}).key });
});

export default router;
